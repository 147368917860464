import { ApiEndPoints } from "../../../core/constants/constants";
import { HttpService } from "./../../../core/services/common/HttpService";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  logout() {
    return this.http.post(ApiEndPoints.logout);
    // throw new Error('Method not implemented.');
  }

  email_otp: string = '';

  constructor(private http : HttpService) { }
  login(data:any){
    return this.http.post(ApiEndPoints.login, data)
  }
  loginSaml(){
    return this.http.get(ApiEndPoints.loginSaml)
  }


  loginCandidatePassword(data:any){
    return this.http.post(ApiEndPoints.loginCandidatePassword, data)
  }

  candidateChangePassword(data:any){
    return this.http.put(ApiEndPoints.loginCandidatePassword, data)
  }


}
export interface LoginObject {
  key: string;
  client_url: string;
  domain: string;
  role: string;
  status: number;
  is_client: boolean;
}
