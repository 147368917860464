import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageKey, secretKeyVariable } from '../constants/constants';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class FunctionHelperService {
  public secretKey = secretKeyVariable;
  public isLogin = false;
  public userEmail = '';
  constructor(
    private _cookie: CookieService,
    private toast: ToastrService,
    private router: Router
  ) {}

  public setUser(data: any) {
    this.isLogin = true;
    const valueToEncode = JSON.stringify(data);
    const encodedData = this.encodeKey(valueToEncode);
    document.cookie = `${LocalStorageKey.userLogin}=${encodedData};path=/;`;
  }

  public get isLogged(): any {
    return !!this._cookie.get(LocalStorageKey.userLogin);
  }

  encodeKey(value: string) {
    return CryptoJS.AES.encrypt(value, this.secretKey.recruitSecretKey.trim()).toString();
  }

  decodeKey(value: string) {
    const decodedValue = CryptoJS.AES.decrypt(value, this.secretKey.recruitSecretKey.trim()).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decodedValue)
  }

  public get token(): any {
    let cookieSaved = { key: '' };
    if (this.isLogged) {
      const tokenData = this._cookie.get(LocalStorageKey.userLogin);
      cookieSaved = this.decodeKey(tokenData);
    }
    return cookieSaved;
  }


  public get userData(): any {
    if (this.isLogged) {
      const tokenData = this._cookie.get(LocalStorageKey.userLogin);
      const cookieSaved = this.decodeKey(tokenData);;
      return cookieSaved;
    } else {
      this.deleteCookie();
      this.toast.error('Unauthenticated...');
      this.router.navigateByUrl('login/');
    }
  }

  deleteUser() {
    console.error('COOKIE CLEARED');
    this._cookie.deleteAll('');
    this._cookie.delete(LocalStorageKey.userLogin);
  }

  public deleteCookie() {
    return new Promise((resolve, reject) => {
      const cookies = document.cookie.split('; ');
      for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.');
        while (d.length > 0) {
          const cookieBase =
            encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path=';
          const p = location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
      document.cookie.split('; ').map((v) => {
        document.cookie =
          v.split('=')[0] +
          `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.${environment.cookieDomain}`;
      });
      document.cookie =
        LocalStorageKey.userLogin +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        'sso-sessionid' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        'auth' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        'csrftoken' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        'sessionid' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      return resolve(true);
    });
  }
}
