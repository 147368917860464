import { FormArray, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';

export enum ApiEndPoints {
  loginSaml = 'auth/login-saml',
  //login
  login = 'login/',
  logout = 'auth/logout',
  jobPositionList = 'job-position/list',
  departmentList = 'department/list',
  jobStatus = 'job-opening-status/list',
  recruiterList = 'user/list',
  countryList = 'country/list',
  skillList = 'skill/list',
  noticePeriodList = 'notice_period/list',
  qualificationList = 'qualifications/list',
  certificationList = 'candidate-certifications/list',
  loginCandidatePassword = 'forgot-password',
  addContract = 'contract/create',

}
export const returnUrl = 'returnUrl';

export const userRole = {
  admin: 'ADMIN',
  hr: 'HR',
  sales: 'SALES',
  approver: 'APPROVER',
  operation: 'OPERATION',
  candidate: 'CANDIDATE',
  interviewer: 'Interviewer',
  ceo: 'SLT CEO',
  director: 'SLT Directors',
};

export const loginUrls = {
  dashboard: '/main/admin/index',
  employee: '/main/admin/home',
  // manager: '/task-entry',
  login: '/login',
  candidateProfile: '/main/user/interview-panel',
  // logout: '/logout',
  // redirect: '/redirect'
};

export const secretKeyVariable = {
  recruitSecretKey: 'BeiNex-RecruitMentPortal@2023-10',
};

export enum ApiStatus {
  ok = 200,
  serverDown = 500,
  created = 201,
  unAuthorized = 401,
  badRequest = 400,
  noContent = 204,
  forbidden = 403,
  notFound = 404,
  notAcceptable = 406,
  conflict = 409,
  unprocessableEntity = 422,
  failedDependency = 424,
  timeOut = 504,
  corsOrigin = 0,
  failed = 'failed',
}

export enum LocalStorageKey {
  userLogin = 'clientDetails',
  // timeDetails = 'timeDetails',
  // userDetails = 'userDetails',
  // sessionId = 'sessionid',
  // validatedData = 'validatedData',
  // usersDetails = 'userDetail',
  // gridDataPreview = 'gridDataPreview',
  // issueDetails = 'issueDetails',
  // controlDetails = 'controlDetails',
  // isUpdate = 'isUpdate',
  // notificationId = 'notificationId',
  // userType = 'userType',
  // customField = 'customField',
  // builderChanged = 'builderChanged',
}

